import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../Config";
import config from "../common/Config";
import Select from "react-select";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Swal from "sweetalert2";

const EditSalesInvoice = () => {
  const { orderId } = useParams();
  const tableRef = useRef(null);
  const [shopDetails, setShopDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [tableRow, setTableRow] = useState([]);
  const [totalVal, setTotalVal] = useState({
    totalstd: 0,
    totalzeroGoods: 0,
    totalTax: 0,
    orderDiscount: 0,
    grandTotal: 0,
    invDiscount: 0,
    over_all_discount: 0,
    orderDiscountPercentage: 0,
    orderDiscountType: "",
  });

  const [flashMessage, setFlashMessage] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState("");
  const [orderOptions, setOrderOptions] = useState([]);
  const [orderType, setOrderType] = useState("");
  const [orderPrice, setOrderPrice] = useState("");
  const [addOrderQty, setAddOrderQty] = useState("");
  const [newProducts, setNewProducts] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const navigateToGRNList = useNavigate();

  const handleQtyChange = (event) => {
    setAddOrderQty(event.target.value);
  };

  const handlePriceChange = (event) => {
    setOrderPrice(event.target.value);
  };

  const handleAddressChange = (event) => {
    //setOrderPrice(event.target.value);

    setOrderDetails((prevState) => ({
      ...prevState, // Spread the previous state to keep other values unchanged
      delivery_address: event.target.value, // Update only the `orderDiscount` field
    }));
  };

  const handleInputChange = (inputValue) => {
    fetchProductNameOptions(inputValue);
  };

  const handleChangeOrderType = (event) => {
    const value = event.target.value;
    setOrderType(value);
  };

  const handleChange = (selectedOption) => {
    setProductInputValue(selectedOption);

    setOrderType("");
    if (selectedOption) {
      const selectedProduct = productOptions.find(
        (option) => option.value === selectedOption.value
      );

      fetchOrderOptions(selectedProduct ? selectedProduct.split : 3);
    } else {
      fetchOrderOptions(3);
    }
  };

  const fetchOrderOptions = async (splitValue) => {
    //console.log(splitValue);
    let data = [];
    if (splitValue == 2) {
      data = [
        { box: "Box", picec: "Box" },
        { box: "Piece", picec: "Piece" },
      ];
    } else if (splitValue == 0) {
      data = [{ box: "Box", picec: "Box" }];
    } else if (splitValue == 1) {
      data = [{ box: "Piece", picec: "Piece" }];
    } else {
      data = [];
    }

    const formattedOptions = data.map((item) => ({
      label: item.box,
      value: item.picec,
    }));
    setOrderOptions(formattedOptions);
  };

  const deleteProductAPI = async (productId) => {
    const Url = `${config.API_URL}/delete_order_product`;

    try {
      const formData = new FormData();
      formData.append("productId", productId);

      const response = await fetch(Url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (data.success === true) {
        console.log("Product deleted successfully");
        // Use filter instead of map to remove the product
        let newArray = productDetails.filter(
          (item) => item.id !== String(productId)
        );
        setProductDetails(newArray);
      } else {
        console.log("Failed to delete product");
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const fetchProductNameOptions = async () => {
    const formData = new FormData();
    const apiSalesRapUrl = `${config.API_URL}/ws_get_product`;

    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();

      console.log(data);

      if (Array.isArray(data.products_details)) {
        const formattedOptions = data.products_details.map((item) => ({
          label: item.code + " " + item.name,
          value: item.id,
          split: item.split,
        }));
        setProductOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching customer options:", error);
    }
  };

  const fetchInvoice = async () => {
    const formData = new FormData();
    formData.append("orderId", orderId);
    const apiUrl = `${config.API_URL}/ws-proforma-invoice`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      //console.log(data);
      setShopDetails(data.details.shop_details);
      setOrderDetails(data.details.orderDetails[0]);
      setProductDetails(data.details.product_details);

      const totalstd = data.details.product_details
        .filter((item) => item.item_tax !== "0.00" && item.item_tax != null)
        .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

      const totalzeroGoods = data.details.product_details
        .filter((item) => item.item_tax === "0.00" || item.item_tax === null)
        .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

        let InvDiscount = 0;
        let totalVat = 0;
        let orderDiscount = 0;
        let grandTotal = 0;

        if(data.details.orderDetails[0].over_all_discount > 0){
          let total = totalzeroGoods + totalstd;
          InvDiscount = (total*parseFloat(data.details.orderDetails[0].over_all_discount))/100;
          totalVat = parseFloat(data.details.orderDetails[0].total_tax) - ((parseFloat(data.details.orderDetails[0].total_tax)*parseFloat(data.details.orderDetails[0].over_all_discount))/100);
          orderDiscount = parseFloat(data.details.orderDetails[0].order_discount);
          grandTotal = totalzeroGoods + totalstd + totalVat - InvDiscount - orderDiscount;
        } else {
         InvDiscount = parseFloat(data.details.orderDetails[0].over_all_amount);
         totalVat = parseFloat(data.details.orderDetails[0].total_tax);
         orderDiscount = parseFloat(data.details.orderDetails[0].order_discount);
         grandTotal = totalzeroGoods + totalstd + totalVat - InvDiscount - orderDiscount;
      }

      // Set the total values
      setTotalVal({
        totalstd: formatNumber(totalstd),
        totalzeroGoods: formatNumber(totalzeroGoods),
        totalTax: totalVat.toFixed(2),
        orderDiscount: orderDiscount.toFixed(2),
        invDiscount: InvDiscount.toFixed(2),
        over_all_discount: (parseFloat(data.details.orderDetails[0].over_all_discount)).toFixed(2),
        grandTotal: (parseFloat(grandTotal)).toFixed(2),
        orderDiscountPercentage: data.details.orderDetails[0].percentage,
        orderDiscountType: data.details.orderDetails[0].discount_type,
      });

    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  useEffect(() => {
    fetchInvoice();
    fetchProductNameOptions();
  }, []);

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2);
  };

  useEffect(() => {
	console.log(productDetails);
    const table = $("#salesInvoice").DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: productDetails,
      columns: [
        { data: null, width: "3%" },
        {
          data: null,
          width: "6%",
          className: "text-center",
          render: function (data, type, row) {
            if (row.is_promoted === "1") {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<span style="color: #f14240;">${
                  row.product_code
                }${"(P)"}</span>`;
              } else {
                return `<span>${row.product_code}${"(P)"}</span>`;
              }
            } else {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<span style="color: #f14240;">${row.product_code}</span>`;
              } else {
                return `<div>${row.product_code}</div>`;
              }
            }
          },
        },
        {
          data: "product_name",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: #f14240;">${row.product_name} - ${row.size}</span>`;
            } else {
              return `<span>${row.product_name} - ${row.size}</span>`;
            }
          },
        },
        {
          data: null,
          width: "6%",
          className: "text-center",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: #f14240;">${parseInt(
                row.unit_quantity
              )}</span>`;
            } else {
              return `<span>${parseInt(row.unit_quantity)}</span>`;
            }
          },
        },
        {
          data: null,
          width: "6%",
          className: "text-center",
          render: function (data, type, row) {
            const orderCount = row.quantity ? parseInt(row.quantity) : 0;

            if (row.is_promoted === "1") {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<span style="color: #f14240;">${orderCount}</span>`;
              } else {
                return `<span>${orderCount}</span>`;
              }
            } else {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<div><input type="text" style="color: #f14240;" data-id="${row.id}" class="form-control order-qty" value="${orderCount}" min="0" /></div>`;
              } else {
                return `<div><input type="text" data-id="${row.id}" class="form-control order-qty" value="${orderCount}" min="0" /></div>`;
              }
            }
          },
        },
        {
          data: "order_type",
          className: "text-center",
          width: "8%",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: #f14240;">${row.order_type}</span>`;
            } else {
              return `<span>${row.order_type}</span>`;
            }
          },
        },

        {
          data: "unit_price",
          width: "6%",
          className: "text-center",
          render: function (data, type, row) {
            const unitPriceVal = row.unit_price ? row.unit_price : 0;
            if (row.is_promoted === "1") {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<span style="color: #f14240;">${unitPriceVal}</span>`;
              } else {
                return `<span>${unitPriceVal}</span>`;
              }
            } else {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<input type="text" style="color: #f14240;" data-id="${row.id}" class="form-control unit-price" value="${unitPriceVal}" min="0.01" />`;
              } else {
                return `<input type="text" data-id="${row.id}" class="form-control unit-price" value="${unitPriceVal}" min="0.01" />`;
              }
            }
          },
        },

        {
          data: "discount",
          width: "6%",
          className: "text-center",
          render: function (data, type, row) {
            const item_discountVal = row.item_discount ? row.item_discount : 0;
            const discountpercentage = row.discount
              ? parseInt(row.discount, 10)
              : 0;
            if (row.is_promoted === "1") {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<span style="color: #f14240;">${item_discountVal}</span>`;
              } else {
                return `<span>${item_discountVal}</span>`;
              }
            } else {
              if (row.discount == "100.00" || row.discount == 100.0) {
                return `<input type="text" style="color: #f14240;" data-id="${row.id}" class="form-control discountpercentage" value="${discountpercentage}" min="0.01" />`;
              } else {
                return `<input type="text" data-id="${row.id}" class="form-control discountpercentage" value="${discountpercentage}" min="0.01" />`;
              }
            }
          },
        },
        {
          data: "item_tax",
          className: "text-center",
          width: "8%",
          render: function (data, type, row) {
            const item_tax = parseFloat(row.item_tax) || 0;
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: #f14240;">${item_tax.toFixed(
                2
              )}</span>`;
            } else {
              return `<span>${item_tax.toFixed(2)}</span>`;
            }
          },
        },
        {
          data: "subtotal",
          className: "text-center",
          width: "8%",
          render: function (data, type, row) {
            const subtotal = parseFloat(row.subtotal) || 0;
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: #f14240;">${subtotal.toFixed(
                2
              )}</span>`;
            } else {
              return `<span>${subtotal.toFixed(2)}</span>`;
            }
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `
						<a href="#" class="btn-delete"  style="color: #f14240;" data-id="${row.id}" tool-tip="Remove product">
						  <i class="fa fa-trash"></i>
						</a>
					  `;
            } else {
              return `
						<a href="#" class="btn-delete" data-id="${row.id}" tool-tip="Remove product">
						  <i class="fa fa-trash"></i>
						</a>
					  `;
            }
          },
          createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
            $(cell)
              .find(".btn-delete")
              .on("click", function (e) {
                e.preventDefault();
                const productId = $(this).data("id");
                deleteProductAPI(productId);
              });
          },
        },
      ],
      columnDefs: [
        {
          targets: [0],
          orderable: false,
        },
      ],
      drawCallback: function (settings) {
        const api = this.api();
        const startIndex = api.page.info().start;

        api
          .column(0, { page: "current" })
          .nodes()
          .each(function (cell, i) {
            cell.innerHTML = startIndex + i + 1;
          });

        $("#salesInvoice").on("draw.dt", function () {
          $("#salesInvoice").off("focusout", ".order-qty").on("focusout", ".order-qty", function () {
              const id = $(this).data("id");
              const newValue = parseFloat($(this).val()) || 0;
              const rowIdx = api
                .rows()
                .indexes()
                .toArray()
                .find((idx) => api.row(idx).data().id == id);

              if (rowIdx !== undefined) {
                const rowData = api.row(rowIdx).data();
                rowData.quantity = newValue;
                // alert(newValue);

                const unit_Price = parseFloat(rowData.unit_price) || 0;
                const discount = parseFloat(rowData.discount) || 0;

                const unitPrice = parseFloat(
                  unit_Price - unit_Price * (discount / 100)
                );
                // alert(newValue);
                // alert(unitPrice);
                rowData.subtotal = newValue * unitPrice;
                // alert(`Subtotal: ${rowData.subtotal.toFixed(2)}`);
                const itemVAT = parseFloat(rowData.tax_rate_percent) || 0;
                // alert(discount);
                rowData.item_tax =
                  itemVAT != 0.0
                    ? ((newValue * unitPrice * itemVAT) / 100).toFixed(2)
                    : "0.00";

                api.row(rowIdx).invalidate().draw();
                const orderId = orderDetails.id;
                const productId = rowData.product_id;
                const output =
                  rowData.order_type === "box"
                    ? "0"
                    : rowData.order_type === "piece"
                    ? "1"
                    : "";
                const order_type = output;
                const qty = newValue;
                newInvoice(orderId, productId, order_type, qty);
                if (orderDetails.group_id == 3) {
                  getOrderDiscount(api, orderDetails.group_id);
                }

                calculateTotals(api);
              } else {
                console.warn(`No row found with ID: ${id}`);
              }
            });

          $("#salesInvoice")
            .off("focusout", ".unit-price")
            .on("focusout", ".unit-price", function () {
              let newValue = parseFloat($(this).val()) || 0;

              if (newValue < 0) {
                newValue = 0;
                $(this).val(newValue);
              }

              const id = $(this).data("id");
              const rowIdx = api
                .rows()
                .indexes()
                .toArray()
                .find((idx) => api.row(idx).data().id == id);

              if (rowIdx !== undefined) {
                const rowData = api.row(rowIdx).data();
                const discount = parseFloat(rowData.discount) || 0;

                const discountedValue = newValue - newValue * (discount / 100);

                rowData.unit_price = formatNumber(newValue);
                const pdtQty = parseFloat(rowData.quantity) || 0;

                rowData.subtotal = formatNumber(discountedValue * pdtQty);

                const itemVAT = parseFloat(rowData.tax_rate_percent) || 0;

                rowData.item_tax =
                  itemVAT != 0.0
                    ? ((discountedValue * pdtQty * itemVAT) / 100).toFixed(2)
                    : 0;

                api.row(rowIdx).invalidate().draw();

                if (orderDetails.group_id == 3) {
                  getOrderDiscount(api, orderDetails.group_id);
                }

                calculateTotals(api);
              } else {
                console.warn(`No row found with ID: ${id}`);
              }
            });

          $("#salesInvoice")
            .off("focusout", ".discountpercentage")
            .on("focusout", ".discountpercentage", function () {
              let discountpercentage = parseFloat($(this).val()) || 0;
              if (discountpercentage < 0) {
                discountpercentage = 0;
                $(this).val(discountpercentage);
              }

              const id = $(this).data("id");
              const rowIdx = api
                .rows()
                .indexes()
                .toArray()
                .find((idx) => api.row(idx).data().id == id);

              if (rowIdx !== undefined) {
                // Initialize rowData before using it
                const rowData = api.row(rowIdx).data();

                // Now you can safely access rowData properties
                const unit_Price = parseFloat(rowData.unit_price) || 0;

                const discountedValue =
                  unit_Price - unit_Price * (discountpercentage / 100);

                rowData.unit_price = formatNumber(unit_Price);
                const pdtQty = parseFloat(rowData.quantity) || 0;
                rowData.discount = formatNumber(discountpercentage);
                rowData.item_discount = formatNumber(discountedValue);
                rowData.subtotal = formatNumber(discountedValue * pdtQty);
                const itemVAT = parseFloat(rowData.tax_rate_percent) || 0;
                rowData.item_tax =
                  itemVAT != 0.0
                    ? ((discountedValue * pdtQty * itemVAT) / 100).toFixed(2)
                    : 0;

                api.row(rowIdx).invalidate().draw();
                if (orderDetails.group_id == 3) {
                  getOrderDiscount(api, orderDetails.group_id);
                }

                calculateTotals(api);
              } else {
                console.warn(`No row found with ID: ${id}`);
              }
            });
        });

        // updateFooter(api);
      },
    });

    return () => {
      table.destroy();
    };
  }, [productDetails, totalVal]);

  const getOrderDiscount = async (api, group_id) => {
    const allData = api.rows().data().toArray();

    // Log the data for debugging
    console.log(allData); // Check what `allData` contains

    const totalzeroGoods =
      allData.length === 0
        ? 0
        : allData
            .filter(
              (item) =>
                item.item_tax === "0.00" ||
                item.item_tax === null ||
                item.item_tax === 0
            )
            .reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0);

    const totalstd =
      allData.length === 0
        ? 0
        : allData
            .filter(
              (item) =>
                item.item_tax !== "0.00" &&
                item.item_tax != null &&
                item.item_tax !== 0
            )
            .reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0);

    const totalamt = totalzeroGoods + totalstd;
    const formData = new FormData();
    formData.append("group_id", group_id);
    formData.append("totalamt", totalamt);
    const apiUrl = `${config.API_URL}/ws_check_Order_Discount`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();

      setTotalVal((prevState) => ({
        ...prevState,
        orderDiscount: parseFloat(data.discount),
        orderDiscountPercentage: data.percentage,
        orderDiscountType: data.discount_type,
      }));

      if (data.success) {
        processNewData(data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };


  // totalTax

  const updateDiscountValue = (e) => {
  let newDiscount = parseFloat(e.target.value);
	  newDiscount = isNaN(newDiscount) ? 0: newDiscount;
	let zeroGoods = parseFloat(totalVal.totalzeroGoods);
	let stdGoods = parseFloat(totalVal.totalstd);
	let total = zeroGoods + stdGoods;
	let InvDiscount = (total*newDiscount)/100;
	let orderDiscount = parseFloat(totalVal.orderDiscount);
  let totalTax = productDetails.reduce((sum, item) => {
    return item.item_tax && item.item_tax !== "0.00" && item.item_tax !== 0
      ? sum + parseFloat(item.item_tax)
      : sum;
  }, 0);

	let totalVat = totalTax - ((totalTax*newDiscount)/100);

	let grandTotal = zeroGoods + stdGoods + totalVat - InvDiscount - orderDiscount;

    setTotalVal((prevState) => ({
      ...prevState,
      invDiscount: InvDiscount.toFixed(2),
      over_all_discount: e.target.value,
      grandTotal: grandTotal.toFixed(2),
      totalTax:totalVat.toFixed(2)
	}));
  };

  const newInvoice = async (orderId, productId, order_type, qty) => {
    const formData = new FormData();
    formData.append("orderId", orderId);
    formData.append("productId", productId);
    formData.append("order_type", order_type);
    formData.append("qty", qty);

    const apiUrl = `${config.API_URL}/ws_check_Product_Promation`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();

      //return data;
      //console.log(data);
      if (data.success) {
        processNewData(data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const processNewData = (rowData) => {
    const oldData = productDetails;
    //const newData = await newInvoice();

    //console.log("function called");

    const newProduct = rowData;
    if (newProduct.item != null) {
      const match = oldData.find(
        (oldProduct) =>
          oldProduct.product_id === newProduct.item.product_id &&
          oldProduct.order_type === newProduct.item.order_type
      );
      if (match) {
        updateRecord(newProduct);
        console.log(newProduct);
      } else {
        //console.log("Insert record");
        //insertRecord(newProduct);
      }
    }
  };

  /*const updateRecord = (product) => {

	  const table = $('#salesInvoice').DataTable();
	  const rowIdx = table.rows().indexes().toArray().find(idx => table.row(idx).data().id === product.item.id);
	  console.log(rowIdx);

	  if (rowIdx !== undefined) {
			console.log(product.item);
			//alert("hello");
		table.row(rowIdx).data(product.item).draw();
	  } else {
		console.warn(`No row found with ID: ${product.id}`);
	  }

	};*/

  const updateRecord = (product) => {
    const table = $("#salesInvoice").DataTable();
    const rowIdx = table
      .rows()
      .indexes()
      .toArray()
      .find(
        (idx) =>
          table.row(idx).data().id === product.item.id &&
          table.row(idx).data().order_type === product.item.order_type
      );
    //console.log(rowIdx);

    if (rowIdx !== undefined) {
      const rowData = table.row(rowIdx).data();
      Object.assign(rowData, product.item);

      if (parseFloat(product.item.quantity) === 0.0) {
        // Remove the row if quantity is 0.00
        table.row(rowIdx).remove().draw();
      } else {
        // Update the row if quantity is not 0.00
        //table.row(rowIdx).data(product.item).draw();

        table.row(rowIdx).data(rowData).invalidate().draw();
      }
    } else {
      console.warn(
        `No row found with Product ID: ${product.item.product_id} and Order Type: ${product.item.order_type}`
      );
    }
  };

  const insertRecord = (product) => {
    $("#salesInvoice").DataTable().row.add(product).draw();
  };

  /*useEffect(() => {
	  fetchInvoice().then(processProductDetails);
	}, []);*/

  //	const newInvoice = async (orderId, productId, order_type, qty) => {}

  const calculateTotals = (api) => {
    const allData = api.rows().data().toArray();

    const totalzeroGoods = allData
      .filter(
        (item) =>
          item.item_tax === "0.00" ||
          item.item_tax === null ||
          item.item_tax === 0
      )
      .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

    const totalstd = allData
      .filter(
        (item) =>
          item.item_tax !== "0.00" &&
          item.item_tax != null &&
          item.item_tax !== 0
      )
      .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

    const totalTax = allData
      .filter(
        (item) =>
          item.item_tax !== "0.00" &&
          item.item_tax != null &&
          item.item_tax !== 0
      ).reduce((sum, item) => sum + parseFloat(item.item_tax), 0);

    const orderDiscount = parseFloat(totalVal.orderDiscount) || 0.0;
    const orderDiscountPercentage = parseFloat(totalVal.orderDiscountPercentage) || 0.0;
    const orderDiscountType = totalVal.orderDiscountType || "";
    const over_all_discount = parseFloat($("#over_all_discount").val()) || parseFloat(totalVal.over_all_discount) || 0.0;

    let adjustedTax;
    if (orderDiscountType == "percentage") {
      adjustedTax = parseFloat(
        (totalTax - (totalTax * orderDiscountPercentage) / 100).toFixed(2)
      );
    } else {
      adjustedTax = totalTax;
    }

    if (over_all_discount != "") {
      adjustedTax = parseFloat(
        (totalTax - (totalTax * over_all_discount) / 100).toFixed(2)
      );
    } else {
      adjustedTax = totalTax;
    }

	let invDiscount = 0;

	if(over_all_discount > 0) {
		invDiscount = ((totalzeroGoods + totalstd)*over_all_discount/100)
	}
	else{
		invDiscount = parseFloat(totalVal.invDiscount) || 0.0;
	}

	// alert(orderDiscount);
	// let total = (totalstd + totalzeroGoods)/
    const grandTotal = totalstd + totalzeroGoods + adjustedTax - invDiscount - orderDiscount;

    setTotalVal({
      totalstd: totalstd.toFixed(2),
      totalzeroGoods: totalzeroGoods.toFixed(2),
      totalTax: adjustedTax.toFixed(2),
      orderDiscount: orderDiscount.toFixed(2),
      invDiscount: invDiscount.toFixed(2),
      over_all_discount: over_all_discount.toFixed(2),
      grandTotal: grandTotal.toFixed(2),
      orderDiscountType: orderDiscountType,
      orderDiscountPercentage: orderDiscountPercentage,
    });

    //calculateTotals(api);
  };

  const calculateTotalForDiscount = () => {
    const allData = productDetails;

    const totalzeroGoods = allData
      .filter(
        (item) =>
          item.item_tax === "0.00" ||
          item.item_tax === null ||
          item.item_tax === 0
      )
      .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

    const totalstd = allData
      .filter(
        (item) =>
          item.item_tax !== "0.00" &&
          item.item_tax != null &&
          item.item_tax !== 0
      )
      .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

    const totalTax = allData
      .filter(
        (item) =>
          item.item_tax !== "0.00" &&
          item.item_tax != null &&
          item.item_tax !== 0
      )
      .reduce((sum, item) => sum + parseFloat(item.item_tax), 0);

    const orderDiscount = parseFloat(totalVal.orderDiscount) || 0.0;
    const invDiscount = parseFloat(totalVal.invDiscount) || 0.0;
    const orderDiscountPercentage =
      parseFloat(totalVal.orderDiscountPercentage) || 0.0;

    const orderDiscountType = totalVal.orderDiscountType || "";
    const disc = invDiscount;
    //const over_all_discount = parseFloat(totalVal.over_all_discount)|| 0.00;
    const over_all_discount =
      parseFloat($("#over_all_discount").val()) ||
      parseFloat(totalVal.over_all_discount) ||
      0.0;
    // alert(over_all_discount2);

    let adjustedTax;
    if (orderDiscountType == "percentage") {
      adjustedTax = parseFloat(
        (totalTax - (totalTax * orderDiscountPercentage) / 100).toFixed(2)
      );
    } else {
      adjustedTax = totalTax;
    }
    if (over_all_discount != "") {
      adjustedTax = parseFloat(
        (totalTax - (totalTax * over_all_discount) / 100).toFixed(2)
      );
    } else {
      adjustedTax = totalTax;
    }
    // alert(adjustedTax);
    const grandTotal =
      totalstd + totalzeroGoods + adjustedTax - disc - orderDiscount;

    setTotalVal({
      totalstd: totalstd.toFixed(2),
      totalzeroGoods: totalzeroGoods.toFixed(2),
      totalTax: adjustedTax.toFixed(2),
      orderDiscount: orderDiscount.toFixed(2),
      invDiscount: invDiscount.toFixed(2),
      over_all_discount: over_all_discount.toFixed(2),
      grandTotal: grandTotal.toFixed(2),
      orderDiscountType: orderDiscountType,
      orderDiscountPercentage: orderDiscountPercentage,
    });
    //calculateTotals(api);
  };


  useEffect(() => {
    if (orderType !== "") {
      const fetchProductPrice = async () => {
        const formData = new FormData();
        formData.append("productId", productInputValue.value);
        formData.append("customerId", orderDetails.customer_id);
        formData.append("order_type", orderType.toLowerCase());

        const apiUrl = `${config.API_URL}/ws_get_product_byid`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });

          if (!response.ok) {
            throw new Error("Failed to fetch product price");
          }
          const data = await response.json();
          //console.log('Product Price Data:', data);
          setOrderPrice(data.price);

          // Handle the data as needed
        } catch (error) {
          console.error("Error fetching product price:", error);
        }
      };

      fetchProductPrice();
    }
  }, [orderType, productInputValue, orderDetails.customer_id]);

  const addProductSubmit = async () => {
    const formData = new FormData();
    formData.append("product_id", productInputValue.value);
    formData.append("orderId", orderDetails.id);
    formData.append("qty_of_invoice", addOrderQty);
    formData.append("price_of_invoice", orderPrice);
    formData.append("order_type", orderType.toLowerCase());

    const apiUrl = `${config.API_URL}/ws_add_new_product`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit add product form");
      }
      const data = await response.json();
      console.log(data);
      Swal.fire({
        title: "success",
        text: "Product added successfully.",
        icon: "success",
        confirmButtonText: "Close",
      });

      //fetchInvoice();
      //fetchProductNameOptions();
      fetchInvoice();
      // window.location.href = config.BASE_URL + '/sales/edit_invoice/' + orderId;
    } catch (error) {
      console.error("Error submit product:", error);
    }
  };

  const submitOrderDetails = async () => {
    const formData = new FormData();
    formData.append("orderId", orderDetails.id);
    formData.append("status", "Accept");
    formData.append("group_id", orderDetails.group_id);
    formData.append(
      "delivery_address",
      orderDetails.delivery_address ? orderDetails.delivery_address : ""
    );
    const productDetailsJson = JSON.stringify(productDetails);
    const productDetailsUrlEncoded = encodeURIComponent(
      JSON.stringify(productDetails)
    );

    formData.append("over_all_amount", totalVal.invDiscount);
    formData.append("over_all_discount", totalVal.over_all_discount);

    formData.append("orderList", productDetailsJson);

    const apiUrl = `${config.API_URL}/ws_update_invoice`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit add product form");
      }
      const data = await response.json();
      console.log(data);

      if (data.success) {
        Swal.fire({
          title: "Success!",
          text: "Invoice successfully Updated.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigateToGRNList("/sales/newsales");
      } else {
        Swal.fire({
          title: "error",
          text: "Something wrong try again.",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      console.error("Error submit product:", error);
    }
  };

  return (
    <>
      <Header />

      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="/sales/newsales">Sales</Link>
              <Link to="/sales/newsales">Sales Invoice</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-addprod-area dashboard">
                <div className="addnew-product-area">
                  <h3 style={{ lineHeight: "20px", margin: "0px 0px 5px 0px" }}>
                    <span>Edit Invoice</span>
                    <button
                      type="button"
                      className="create-catalogue-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#add-product"
                    >
                      Add New Product
                    </button>
                  </h3>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-6 col-sm-6">
                    <p>Customer Name - {orderDetails.name}</p>
                    <p>Account No - {orderDetails.account_no} </p>
                  </div>
                  <div className="col-12 col-lg-6 col-sm-6">
                    <div className="row mb-3">
                      <div className="col-12 col-lg-4 col-sm-12">
                        <label
                          htmlFor="inputText"
                          className="col-form-label"
                          style={{ float: "left" }}
                        >
                          Delivery Address:
                        </label>
                      </div>
                      <div className="col-sm-col-12 col-lg-8 col-sm-12">
                        <input
                          type="text"
                          onChange={handleAddressChange}
                          value={orderDetails.delivery_address}
                          className="form-control delivery-add"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="verical-scrl-wrapper common-table sales-edit-invoice"
                  style={{ margin: "0px 0px 32px 0px" }}
                  id="style-3"
                >
                  <table
                    className="table table-bordered resizable"
                    id="salesInvoice"
                  >
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Order Qty</th>
                        <th>Allot Qty</th>
                        <th>Type</th>
                        <th>Unit price</th>
                        <th>Disc(%)</th>
                        <th>VAT Amount</th>
                        <th>Net Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>01</td>
                        <td>1107</td>
                        <td>
                          <p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p>
                        </td>
                        <td>2</td>
                        <td>
                          <input type="text" class="form-control" />
                        </td>
                        <td>Box</td>
                        <td>
                          <input type="text" class="form-control" />
                        </td>
                        <td>25.98</td>
                        <td>5.20</td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                  <footer>
  <td colSpan="11" className="footer-col">
    <div
      style={{
        background: "#eee",
        padding: "5px",
        border: "1px solid #eee",
        borderTop: "none",
        borderBottom: "none",
      }}
    >
      <table className="table table-bordered table-condensed totals">
        <tbody>
          <tr className="warning">
            <td>Zero Goods : {totalVal.totalzeroGoods}</td>
            <td>Std Goods : {totalVal.totalstd}</td>
            <td>VAT Amount : {totalVal.totalTax}</td>
            <td>
              Promo Discount: {totalVal.orderDiscount || "0.00"}{" "}
              {totalVal.orderDiscountType === "percentage" ? (
                <span title="Discount in Percentage" style={{color:'blue'}}>
                ({totalVal.orderDiscountPercentage} %)
              </span>
              ) : ''}
            </td>
            <td>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <label htmlFor="discountInput">Inv Disc(%):</label>
                <input
                  id="discountInput"
                  type="text"
                  value={totalVal.over_all_discount}
                  onChange={(e) => updateDiscountValue(e)}
                  className="over_all_discount"
                  style={{
                    width: "100px",
                    marginRight: "10px",
                  }}
                />
              </div>
            </td>
            <td>
              Inv Disc Amt:{" "}
              <span id="actualDiscount">
                {totalVal.invDiscount || "0.00"}
              </span>
            </td>
            <td>Grand Total : {totalVal.grandTotal}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </td>
</footer>

                </div>
                <div className="row">
                  <div className="col-4">
                    <button
                      type="button"
                      onClick={submitOrderDetails}
                      class="selectdate-btn"
                      style={{ margin: "0px 0px 25px 0px", maxWidth: "150px" }}
                    >
                      Confirm & Submit
                    </button>
                  </div>
                  <div className="col-8">
                    <p>
                      Sale Note:{" "}
                      {orderDetails.staff_note ? orderDetails.staff_note : ""}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="add-product" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Product</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                <form>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="desc"
                      className="col-sm-4 col-form-label-left"
                    >
                      Select Product
                    </label>
                    <div className="col-sm-8">
                      <Select
                        isClearable
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        options={productOptions}
                        placeholder="Select Product *"
                        value={productInputValue}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="desc"
                      className="col-sm-4 col-form-label-left"
                    >
                      Order Type
                    </label>
                    <div className="col-sm-8">
                      <select
                        value={orderType}
                        onChange={handleChangeOrderType}
                        className="form-select"
                      >
                        <option value="">Select Order Type*</option>
                        {orderOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="desc"
                      className="col-sm-4 col-form-label-left"
                    >
                      Price
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        onChange={handlePriceChange}
                        className="form-control"
                        value={orderPrice}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="desc"
                      className="col-sm-4 col-form-label-left"
                    >
                      QTY
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        onChange={handleQtyChange}
                        className="form-control"
                        value={addOrderQty}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="form-group">
                    <input
                      type="button"
                      className="btn btn-secondary common-closebtn"
                      data-bs-dismiss="modal"
                      value="Close"
                      style={{ float: "right", width: "auto" }}
                    />
                    <input
                      type="button"
                      onClick={addProductSubmit}
                      className="common-popup-btn"
                      data-bs-dismiss="modal"
                      value="Save Changes"
                      style={{
                        float: "right",
                        width: "auto",
                        marginRight: "15px",
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default EditSalesInvoice;
