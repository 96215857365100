let API_URL, BASE_URL, REACT_APP_HOMEPAGE_URL, PREFIX;

const getTenantFromURL = () => 
{
  const url = window.location.hostname;
  const tenantId = url.split('.')[0];  
  return url;  
};

const currentURL = getTenantFromURL();
// console.log(currentURL);

if (currentURL === 'localhost') {
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'http://localhost:3000';
  REACT_APP_HOMEPAGE_URL = 'https://localhost:3000';
  PREFIX = 'tsc';
} else if (currentURL === 'ddsnacks.co.uk') {
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://ddsnacks.co.uk';
  REACT_APP_HOMEPAGE_URL = 'https://ddsnacks.co.uk';
  PREFIX = 'dds';
} else if (currentURL === 'sales.ddsnacks.co.uk') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://sales.ddsnacks.co.uk';
  REACT_APP_HOMEPAGE_URL='https://sales.ddsnacks.co.uk';
  PREFIX ='dds';
}else if (currentURL === 'dan.rameegroup.co.in') {
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://dan.rameegroup.co.in';
  REACT_APP_HOMEPAGE_URL = 'https://dan.rameegroup.co.in';
  PREFIX = 'dan';
} else if (currentURL === 'karoon.rameegroup.co.in') {
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://karoon.rameegroup.co.in';
  REACT_APP_HOMEPAGE_URL = 'https://karoon.rameegroup.co.in';
  PREFIX = 'knm';
} else if (currentURL === 'tsdev.rameegroup.co.in') {
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://tsdev.rameegroup.co.in';
  REACT_APP_HOMEPAGE_URL = 'https://tsdev.rameegroup.co.in';
  PREFIX = 'tsc';
} else if (currentURL === 'aytac.rameesystems.com') {
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://aytac.rameesystems.com';
  REACT_APP_HOMEPAGE_URL = 'https://aytac.rameesystems.com';
  PREFIX = 'ayt';
} else if (currentURL === 'kmfood.co.uk') {
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://kmfood.co.uk';
  REACT_APP_HOMEPAGE_URL = 'https://kmfood.co.uk';
  PREFIX = 'knm';
} else if (currentURL === 'tsc.ebusso.com') {
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://tsc.ebusso.com';
  REACT_APP_HOMEPAGE_URL = 'http://tsc.ebusso.com';
  PREFIX = 'tss';
} else if (currentURL === 'dds.ebusso.com') {
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://dds.ebusso.com';
  REACT_APP_HOMEPAGE_URL = 'https://dds.ebusso.com';
  PREFIX = 'ddd';
}else if (currentURL === 'daninternational.rameesystems.com') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://daninternational.rameesystems.com';
  REACT_APP_HOMEPAGE_URL='https://daninternational.rameesystems.com';
  PREFIX ='dan';
}else if (currentURL === 'everest.rameesystems.com') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://everest.rameesystems.com';
  REACT_APP_HOMEPAGE_URL='https://everest.rameesystems.com';
  PREFIX ='eve';
}

export { API_URL, BASE_URL, REACT_APP_HOMEPAGE_URL, PREFIX };
